import { FrontEndModel } from "@/models/FrontEndModel";
import { ScoutingReportScore } from "@/../types";
import { AthleteProfileModel, CoachProfileModel, PlayerOnTeam, TeamModel } from "@/models";
import { isValidUUID, isEmpty, isNotEmpty, isNotEmptyArray } from "@/pipes";
import { athleteApi } from "@/api/AthleteApi";
import { teamApi } from "@/api/TeamApi";
import { coachApi } from "@/api/CoachApi";
import { sportApi } from "@/api/SportApi";
import { cloneDeep } from "lodash";
import { SportModel } from "../sport";
import { organizationApi } from "@/api/OrganizationApi";
import { OrganizationOnTeam } from "../organization/OrganizationOnTeam";

export class AthleteReportCategory {
	name: string;
	rating?: ScoutingReportScore;
	notes?: string;
}

export class AthleteReportCategoryHeading extends AthleteReportCategory {
	categories: Array<AthleteReportCategory> = [];
	expanded: boolean = false;
}

export const AthleteReportDefaultHeadings = [
	{
		name:'Technical', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Dribbling', rating: undefined, notes: undefined},
			{name:'Passing', rating: undefined, notes: undefined},
			{name:'Shooting', rating: undefined, notes: undefined},
			{name:'Control', rating: undefined, notes: undefined},
		]
	},
	{
		name:'Tactical', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Positioning', rating: undefined, notes: undefined},
			{name:'Movement', rating: undefined, notes: undefined},
			{name:'Awareness', rating: undefined, notes: undefined},
			{name:'Decisions', rating: undefined, notes: undefined},
		]
	},
	{
		name:'Physical', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Speed', rating: undefined, notes: undefined},
			{name:'Strength', rating: undefined, notes: undefined},
			{name:'Stamina', rating: undefined, notes: undefined},
			{name:'Agility', rating: undefined, notes: undefined}
		]
	},
	{
		name:'Mental', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Coachability', rating: undefined, notes: undefined},
			{name:'Confidence', rating: undefined, notes: undefined},
			{name:'Focus', rating: undefined, notes: undefined},
			{name:'Leadership', rating: undefined, notes: undefined},
		]
	},
	{
		name:'Social', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Teamwork', rating: undefined, notes: undefined},
			{name:'Respect', rating: undefined, notes: undefined},
			{name:'Sportsmanship', rating: undefined, notes: undefined},
			{name:'Adaptability', rating: undefined, notes: undefined},
		]
	},
];	

export class AthleteReportModel extends FrontEndModel {
	athleteId: string;
	teamId: string;
	coachId: string;
	date: Date;
	playerType: string;
	number: string;
	position: string;
	rating: ScoutingReportScore;
	notes: string;
	tags: string;
	headings: Array<AthleteReportCategoryHeading>;

	athlete: AthleteProfileModel;
	team: TeamModel;
	coach: CoachProfileModel;

    constructor(athleteId: string = undefined, teamId: string = undefined, coachId: string = undefined) {
        super();

        this.athleteId = athleteId;
        this.teamId = teamId;
        this.coachId = coachId;

        this.date = new Date();
    }

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);

		if( obj['date'] ) this.date = new Date(obj['date']);
		if( obj['headings']) this.headings = cloneDeep(obj.headings);

		return this;
	}
}

export class AthleteReportModelEx extends AthleteReportModel {
	athlete: AthleteProfileModel;
	coach: CoachProfileModel;
	team: TeamModel;
	player: PlayerOnTeam;
	sport: SportModel;

	async initialize() {
		await this.loadHeadings();
	}
	async updateReport(athleteId: string, teamId: string, coachId: string) {
		this.athleteId = athleteId;
		this.teamId = teamId;
		this.coachId = coachId;
		if( this.athlete?.id !== athleteId ) this.athlete = undefined;
		if( this.team?.id !== teamId ) this.team = undefined;
		if( this.coach?.id !== coachId ) this.coach = undefined;
		await this.initialize();
	}
    async loadAthlete(athletes: Array<AthleteProfileModel> = [], teams: Array<TeamModel> = []) {
		if( this.HasAthleteProfile ) return;
		
        if( isEmpty(this.athleteId) ) return;

		this.athlete = await athletes.find(a => a.id === this.athleteId);
		if( !this.HasAthleteProfile ) this.athlete = await athleteApi.publicFindById(this.athleteId);

		await this.loadAthleteTeam(teams);
		if( !this.HasTeam ) return;

		this.player = this.team.players.find(p => p.athleteId === this.athleteId);
		if( this.HasTeamPlayer ) {
			this.number = this.player.number;
			this.position = this.player.position;
		}
		if( this.HasAthleteProfile && isEmpty(this.headings) ) await this.loadHeadings();
    }
	async loadAthleteCoach(coaches: Array<CoachProfileModel> = []) {
		if( isEmpty(this.coachId) ) return;

		this.coach = coaches.find(c => c.id === this.coachId);
		if( !this.HasCoachProfile ) this.coach = await coachApi.findById(this.coachId);
		if( this.HasCoachProfile && isEmpty(this.headings) ) await this.loadHeadings();
	}
	async loadAthleteTeam(teams: Array<TeamModel> = []) {
		if( isEmpty(this.teamId) && this.HasAthleteProfile ) this.teamId = this.athlete.currentTeam;
		if( isEmpty(this.teamId) && this.HasAthleteProfile && isNotEmptyArray(this.athlete.teams) ) this.teamId = this.athlete.teams[this.athlete.teams.length - 1].id;

		if( isNotEmptyArray(teams) ) {
			this.team = teams.find(t => t.id === this.teamId);
		}
		if( !this.HasTeam ) {
			if( isValidUUID(this.teamId) ) {
				this.team = await teamApi.findById(this.teamId);
			} else if( this.HasAthleteProfile ) {
				const teams = await teamApi.findByAthleteId(this.athlete?.id);
				if( isNotEmptyArray(teams) ) this.team = teams[teams.length - 1];
			}

			if( isEmpty(this.headings) ) await this.loadHeadings();
		}
	}

	async loadTeam() {
		// check if team was previously loaded
		if( this.HasTeam ) return;

		// load team using teamId
		if( isNotEmpty(this.teamId) ) this.team = await teamApi.findById(this.teamId);
		if( this.HasTeam ) return;

		// no team found, use athlete team
		if( isNotEmpty(this.athleteId) ) {
			await this.loadAthlete();
			if( this.HasAthleteProfile ) {
				this.team = this.athlete.CurrentTeam;
				this.teamId = this.team?.id;
			}
		}
	}
	async loadCoach() {
		if( this.HasCoachProfile ) return;
		
		if( isNotEmpty(this.coachId) ) this.coach = await coachApi.findById(this.coachId);
	}
	async loadSport(sportName: string) {
		this.sport = await sportApi.findByName(sportName);
	}

	get DebugInfo() {
		return ({aI: this.athleteId, cI: this.coachId, tI: this.teamId, a: this.athlete, c: this.coach, t: this.team});
	}
	async loadHeadings() {
		await this.loadAthlete();
		await this.loadTeam();
		await this.loadCoach();

		// find organization headings
		if( this.HasTeam ) {
			if( isNotEmpty(this.team.organizations) ) {
				for( const org of this.team.organizations ) {
					const orgOnTeam: OrganizationOnTeam = Object.assign(org);
					const organization = await organizationApi.findById(orgOnTeam.orgId);
					if( isNotEmpty(organization?.athleteReportHeadings) ) {
						this.headings = cloneDeep(organization.athleteReportHeadings);
					}
				}
			}
		}
		if( isNotEmpty(this.headings) ) return;

		// use the sport headings
		// try the team sport
		var sportName: string = this.team?.Sport;
		// if no sport, use coach sport
		if( isEmpty(sportName) ) {
			await this.loadCoach();
			sportName = this.coach?.SportName;
		}

		// if still no sport, use athlete sport
		if( isEmpty(sportName) ) {
			await this.loadAthlete();
			sportName = this.athlete?.Sport.name;
		}

		// if sport is specified
		if( isNotEmpty(sportName) ) {
			await this.loadSport(sportName);
			if( isNotEmpty(this.sport) && isNotEmptyArray(this.sport.athleteReportHeadings) ) {
				this.headings = cloneDeep(this.sport.athleteReportHeadings);
			}
		} 
		
		if( isNotEmpty(this.headings ) ) return;

		// default headings
		this.headings = AthleteReportDefaultHeadings;
	}

	get HasAthleteProfile(): boolean {
		return isNotEmpty(this.athlete);
	}
	get HasCoachProfile(): boolean {
		return isNotEmpty(this.coach);
	}
	get HasTeam(): boolean {
		return isNotEmpty(this.team);
	}
	get HasTeamPlayer(): boolean {
		return isNotEmpty(this.player);
	}

	get FirstName(): string {
		if( isEmpty(this.athlete) ) return '';
		return this.athlete.firstName;
	}
	get LastName(): string {
		if( isEmpty(this.athlete) ) return '';
		return this.athlete.lastName;
	}
	get PlayerName(): string {
		if( isEmpty(this.athlete) ) return '';
		if( isEmpty(this.athlete.lastName) ) return this.athlete.firstName;
		if( isEmpty(this.athlete.firstName) ) return this.athlete.lastName;
		return `${this.athlete.firstName} ${this.athlete.lastName}`;
	}
	get DateOfBirth(): Date {
		if( isEmpty(this.athlete) ) return new Date('March 16 2006');
		return this.athlete.birthDate;
	}
	get AthleteName(): string {
		if( !this.HasAthleteProfile ) return this.athleteId;
		return this.athlete.FullName;
	}
	get TeamName(): string {
		if( !this.HasTeam ) return this.teamId;
		return this.team.name;
	}
	get CoachName(): string {
		if( !this.HasCoachProfile ) return this.coachId;
		return this.coach.FullName;
	}

    get OverallScore(): AthleteReportCategory {
        const category: AthleteReportCategory = new AthleteReportCategory;
        category.name = this.athlete? this.athlete.FullName : 'athlete';
        category.rating = this.rating;
        return category;
    }
}