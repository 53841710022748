
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';
import { notificationStore } from '@/store';
import { isNotEmpty } from '@/pipes';

@Component
export default class CopyToClipboard extends Mixins(VuetifyMixin){
	@Prop({ default: 'primary' }) hoverColor: string;
	@Prop() text: string;
	@Prop() clipboardText: string;
	@Prop() tooltipText: string;
	@Prop({ type: Boolean }) noIcon: boolean;
	@Prop({ type: Boolean }) button: boolean;
	@Prop({ type: Boolean }) dark: boolean;
	@Prop({ type: String, default: 'primary'}) defaultIconColor: string;
	@Prop({ type: String, default: 'mdi-content-copy' }) icon: string;
	@Prop({ type: Boolean }) smallIcon;
	@Prop({ type: Boolean }) smallText;

	$refs:{
		animatedText: HTMLSpanElement;
		copyToClipboardEl: HTMLSpanElement;
	}

	get Color(){
		return this.getColor(this.dark ? 'white' : this.defaultIconColor);
	}
	get HoverColor(){
		return this.getColor(this.hoverColor);
	}
	getTextColor(hovered: boolean){
		return hovered ? this.HoverColor : this.Color;
	}

	ClipText( t: string, front: number = 35, back: number = 5 ): string {
		if( !t ) return '';
		if( ( front + back + 3 ) > t.length ) return t;
		return t.substring(0, front) + '...' + t.substring(t.length - back);
	}

	get TextToDisplay(): string {
		if( !this.useClipboardText ) return this.text;
		if( this.useClipboardText.length > 40 ) return this.ClipText(this.useClipboardText);
		return this.useClipboardText;
	}
	
	useClipboardText: string;
	@Watch('clipboardText') setupClipboardText(){
		if(this.clipboardText && this.clipboardText.length > 0){
			this.useClipboardText = this.clipboardText;
		}else{
			this.useClipboardText = this.text;
		}
	}

	get ToolTipText(): string {
		const tooltipInfo = ( this.tooltipText && this.tooltipText.length > 0 )? this.tooltipText : this.ClipText(this.clipboardText, 15, 3);
		return `Copy ${tooltipInfo} to clipboard`;
	}

	mounted(){
		this.setupClipboardText();
	}

	animationText = '';
	runClickAnimation(){
		this.$refs.animatedText.classList.remove('run-animation');
		this.$refs.animatedText.offsetWidth;
		this.$refs.animatedText.classList.add('run-animation');
	}
	copyToClipboard(){
		if(this.button === true){
			this.$emit('click');
		}
		this.animationText = this.clipboardText;
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = this.useClipboardText;
		this.$refs.copyToClipboardEl.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		this.$refs.copyToClipboardEl.removeChild(selBox);
		this.runClickAnimation();
		const notificationInfo: string = isNotEmpty(this.tooltipText)? this.tooltipText : `'${this.TextToDisplay}'`;
		notificationStore.pushSnackbarSuccess({message: `Copied ${notificationInfo} to clipboard`});
		this.animationText = '';
	}
}
