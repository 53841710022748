import Vue from 'vue';
import { VNumber } from "@maxflex/v-number";
Vue.component('v-number', VNumber);

import Page from '../views/Page';
import PageTitle from './ui/PageTitle';
import PageSubTitle from './ui/PageSubTitle';
import SectionHeading from './ui/SectionHeading';
import SectionLabel from './ui/SectionLabel';
import ButtonToggle from './ui/ButtonToggle';
import DashboardSectionLabel from './ui/DashboardSectionLabel';
import FormTitle from './ui/FormTitle';
import FormGroupHeading from './ui/FormGroupHeading';
import FormAddArea from './forms/FormAddArea';
import RoundedButton from './forms/RoundedButton';
import HolidaySale from './promotions/HolidaySale.vue';
import TooltipButton from './ui/TooltipButton.vue';
import TooltipListItem from './ui/TooltipListItem.vue';
import LoadingWait from './ui/LoadingWait';
import RoundLoader from './ui/RoundLoader';
import UserChip from './ui/UserChip';
import AthleteChip from './ui/AthleteChip';
import ScoreChip from './ui/ScoreChip';
import SimpleChip from './ui/SimpleChip';
import CopyToClipboard from './ui/CopyToClipboard';
import FailureStateCard from './ui/FailureStateCard';
import PromotionsCard from './ui/PromotionsCard';
import ShareQRDialog from './ui/ShareQRDialog';
import Alert from './ui/Alert';
import CircledAvatar from './ui/CircledAvatar';
import Dialog from './ui/Dialog';
import ConfirmationDialog from './ui/ConfirmationDialog';
import ContextMenu from './ui/ContextMenu';
import PrimaryText from './ui/PrimaryText';
import SubPrimaryText from './ui/SubPrimaryText';
import SecondaryText from './ui/SecondaryText';
import HelpButton from './ui/HelpButton';
import HelpInfo from './ui/HelpInfo';
import GetStringDialog from './ui/GetStringDialog';
import GetFileDialog from './ui/GetFileDialog';
import AthleteEditInfoDialog from './ui/AthleteEditInfoDialog';
import AssessmentEditDialog from './ui/AssessmentEditDialog';
import AssessmentReportEditHeadingDialog from './ui/AssessmentReportEditHeadingDialog';
import MindsetEditDialog from './ui/MindsetEditDialog';
import InvitePlayerDialog from '../components/teams/InvitePlayerDialog';
import Jersey from '../components/teams/Jersey';
import TeamContactCard from './ui/TeamContactCard';

import DatePicker from './forms/DatePicker';

import ProfileProvider from './hoc/ProfileProvider';
import ProfilePictureProvider from './hoc/ProfilePictureProvider';
import MindsetProvider from './hoc/MindsetProvider';

import SpeedometerSvg from '../components/svg/SpeedometerSvg.vue';
import StopwatchSvg from '../components/svg/StopwatchSvg.vue';
import LightningSvg from '../components/svg/LightningSvg.vue';
import AgilitySvg from '../components/svg/AgilitySvg.vue';
import HeartVitalSvg from '../components/svg/HeartVitalSvg.vue';
import RulerSvg from '../components/svg/RulerSvg.vue';

import BAChip from '../components/inputs/BAChip.vue';
import BAAutocomplete from '../components/inputs/BAAutocomplete.vue';
import BASelect from '../components/inputs/BASelect.vue';
import BACombobox from '../components/inputs/BACombobox.vue';
import BATextField from '../components/inputs/BATextField.vue';

import PartnerCard from '../components/partners/PartnerCard.vue';
import PartnerProductCard from '../components/partners/PartnerProductCard.vue';
import PartnerProductPurchasedCard from '../components/partners/PartnerProductPurchasedCard.vue'

Vue.component('Page', Page);
Vue.component('PageTitle', PageTitle);
Vue.component('PageSubTitle', PageSubTitle);
Vue.component('SectionHeading', SectionHeading);
Vue.component('SectionLabel', SectionLabel);
Vue.component('ButtonToggle', ButtonToggle);
Vue.component('DashboardSectionLabel', DashboardSectionLabel);
Vue.component('FormTitle', FormTitle);
Vue.component('FormGroupHeading', FormGroupHeading);
Vue.component('FormAddArea', FormAddArea);
Vue.component('RoundedButton', RoundedButton);
Vue.component('HolidaySale', HolidaySale);
Vue.component('TooltipButton', TooltipButton);
Vue.component('TooltipListItem', TooltipListItem);
Vue.component('LoadingWait', LoadingWait);
Vue.component('RoundLoader', RoundLoader);
Vue.component('UserChip', UserChip);
Vue.component('AthleteChip', AthleteChip);
Vue.component('ScoreChip', ScoreChip);
Vue.component('SimpleChip', SimpleChip);
Vue.component('CopyToClipboard', CopyToClipboard);
Vue.component('FailureStateCard', FailureStateCard);
Vue.component('PromotionsCard', PromotionsCard);
Vue.component('ShareQRDialog', ShareQRDialog);
Vue.component('Alert', Alert);
Vue.component('CircledAvatar', CircledAvatar);
Vue.component('Dialog', Dialog);
Vue.component('ConfirmationDialog', ConfirmationDialog);
Vue.component('ContextMenu', ContextMenu);
Vue.component('PrimaryText', PrimaryText);
Vue.component('SubPrimaryText', SubPrimaryText);
Vue.component('SecondaryText', SecondaryText);
Vue.component('HelpButton', HelpButton);
Vue.component('HelpInfo', HelpInfo);
Vue.component('GetStringDialog', GetStringDialog);
Vue.component('GetFileDialog', GetFileDialog);
Vue.component('AthleteEditInfoDialog', AthleteEditInfoDialog);
Vue.component('AssessmentEditDialog', AssessmentEditDialog);
Vue.component('AssessmentReportEditHeadingDialog', AssessmentReportEditHeadingDialog)
Vue.component('MindsetEditDialog', MindsetEditDialog);
Vue.component('InvitePlayerDialog', InvitePlayerDialog);
Vue.component('Jersey', Jersey);
Vue.component('TeamContactCard', TeamContactCard);

Vue.component('DatePicker', DatePicker);

Vue.component('ProfileProvider', ProfileProvider);
Vue.component('ProfilePictureProvider', ProfilePictureProvider);
Vue.component('MindsetProvider', MindsetProvider);

Vue.component('SpeedometerSvg', SpeedometerSvg);
Vue.component('StopwatchSvg', StopwatchSvg);
Vue.component('LightningSvg', LightningSvg);
Vue.component('AgilitySvg', AgilitySvg);
Vue.component('HeartVitalSvg', HeartVitalSvg);
Vue.component('RulerSvg', RulerSvg);

Vue.component('BAChip', BAChip);
// Inputs 
Vue.component('BAAutocomplete', BAAutocomplete);
Vue.component('BASelect', BASelect);
Vue.component('BACombobox', BACombobox);
Vue.component('BATextField', BATextField);

Vue.component('PartnerCard', PartnerCard);
Vue.component('PartnerProductCard', PartnerProductCard);
Vue.component('PartnerProductPurchasedCard', PartnerProductPurchasedCard);