/* MindsetDisplayMixin.ts */

import { Component, Mixins } from 'vue-property-decorator';
import { GetMindsetArchetype } from '@/../types/constants/ryzer';
import { mindsetReportApi } from '@/api/MindsetReportApi'
import { notificationStore } from '@/store';
import { AppHostnameMixin, MyCoachMixin, StringsMixin } from '@/mixins';

@Component
export class MindsetDisplayMixin extends Mixins(AppHostnameMixin, StringsMixin, MyCoachMixin) {
	GetMindsetColor(archetype: string): string {
		const mindsetArchetype = GetMindsetArchetype(archetype);
		return mindsetArchetype.color;
	}
	GetMindsetImage(archetype: string): string {
		const mindsetArchetype = GetMindsetArchetype(archetype);
		return mindsetArchetype.image;
	}

	requestAthlete: string = undefined;
	requestAthleteSuccess: string = undefined;
	IsRequestAthlete(athleteId: string): boolean {
		return( this.requestAthlete && this.requestAthlete == athleteId);
	}
	IsRequestSuccess(athleteId: string): boolean {
		return( this.requestAthleteSuccess && this.requestAthleteSuccess == athleteId);
	}
	progressDialog: boolean = false;
	async goToRequestMindsetReport(athleteId: string): Promise<void> {
		if( this.AthleteAppActive ) return;

		this.progressDialog = true;
		this.requestAthlete = athleteId;
		this.requestAthleteSuccess = undefined;
		const resultUser = await mindsetReportApi.createMindsetUser(athleteId);
		if( this.IsNotEmpty(resultUser?.Error) ) {
			notificationStore.pushSnackbarError({message: resultUser.Error});
		} else {
			const resultInvite = await mindsetReportApi.sendMindsetInvitation(athleteId, this.MyCoachId);		
			if( this.IsNotEmpty(resultInvite?.Error) ) {
				notificationStore.pushSnackbarError({message: resultInvite.Error});
			} else {
				notificationStore.pushSnackbarSuccess({message: `Mindset Test request has been sent to this athlete`});
			}
		}
		this.requestAthlete = undefined;
		this.requestAthleteSuccess = athleteId;
		this.progressDialog = false;
	}
	async goToMindsetReminder(athleteId: string): Promise<void> {
		if( this.AthleteAppActive ) return;

		this.progressDialog = true;
		this.requestAthlete = athleteId;
		this.requestAthleteSuccess = undefined;
		const result = await mindsetReportApi.sendMindsetInvitation(athleteId, this.MyCoachId);
		if( this.IsNotEmpty(result?.Error) ) {
			notificationStore.pushErrorNotificationMessage(result.Error);
		} else {
			notificationStore.pushSnackbarSuccess({message: `Mindset Test reminder has been sent to this athlete`});
		}
		this.requestAthlete = undefined;
		this.requestAthleteSuccess = athleteId;
		this.progressDialog = false;
	}

	gotoRyzer() {
		window.open("https://ryzer.com/Mindset/", '_blank')
	}
	gotoRyzerArchetype(archetype: string) {
		const mindsetArchetype = GetMindsetArchetype(archetype);
		window.open(mindsetArchetype.url, '_blank');
	}
}
