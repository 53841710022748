/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Vue } from 'vue-property-decorator';
import { AdminAppHostname, AdminAppHostnames, AthleteAppHostname, ClubAdminAppHostname, PublicAppHostname, ClubAdminAppHostnames, CoachingAppHostname } from '@/../types/constants/hostnames';
import { CDN } from "@/../types/constants/cdns";
import { EnvironmentName } from '@/../types/constants/environments';
import { config } from '@/config/config';
import { AppType } from "@/../types/AppType";
import { UserProfileModel } from '@/models/UserProfileModel';
import { isCoachRoute } from '@/router/routeGuards/coachAuthGuard';
import { userStore } from '@/store';
import * as Routes from '@/../types/constants/web_client_user.routes';
import * as OrgAdminRoutes from '@/../types/constants/org-admin.routes';
import * as AdminRoutes from '@/../types/constants/admin.routes';

@Component
export class AppHostnameMixin extends Vue {
	envName: EnvironmentName = config.BA_ENV;

	get Hostname(): string {
		return location.host;
	}

	get AdminAppActive(): boolean {
		return AdminAppHostnames.includes(this.Hostname as any);
	}
	get AthleteAppActive(): boolean {
		return userStore.currentProfileData && userStore.currentProfileData.type === 'athlete';
	}
	get ClubAdminAppActive(): boolean {
		return ClubAdminAppHostnames.includes(this.Hostname as any);
	}
	get CoachAppActive(): boolean {
		return userStore.currentProfileData && userStore.currentProfileData.type === 'coach';
	}

	get getAppType(): AppType {
		if (this.AdminAppActive) return "admin"
		else if (this.ClubAdminAppActive) return "clubadmin"
		else if (this.CoachAppActive) return "coach"
		else if (this.AthleteAppActive) return "athlete"
		else return null
	}

	get AdminAppUrl(): AdminAppHostname {
		switch (this.envName) {
		case 'local':
			return 'admin.local.bestathletes.co:8080';
		case 'dev':
			return 'admin.dev.bestathletes.co';
		case 'test':
		case 'testing':
			return 'admin.testing.bestathletes.co';
		case 'stage':
		case 'staging':
			return 'admin.stage.bestathletes.co';
		case 'production':
			return 'admin.app.bestathletes.co';
		default:
			return 'admin.local.bestathletes.co:8080';
		}
	}
	get AthleteAppUrl(): AthleteAppHostname {
		switch (this.envName) {
		case 'local':
			return 'app.local.bestathletes.co:8080';
		case 'dev':
			return 'dev.bestathletes.co';
		case 'test':
		case 'testing':
			return 'testing.bestathletes.co';
		case 'stage':
		case 'staging':
			return 'stage.bestathletes.co';
		case 'production':
			return 'app.bestathletes.co';
		default:
			return 'app.local.bestathletes.co:8080';
		}
	}
	get CoachAppUrl(): CoachingAppHostname {
		switch (this.envName) {
		case 'local':
			return 'app.local.bestathletes.co:8080/c';
		case 'dev':
			return 'dev.bestathletes.co/c';
		case 'test':
		case 'testing':
			return 'testing.bestathletes.co/c';
		case 'stage':
		case 'staging':
			return 'stage.bestathletes.co/c';
		case 'production':
			return 'app.bestathletes.co/c';
		default:
			return 'app.local.bestathletes.co:8080/c';
		}
	}
	get ClubAdminAppUrl(): ClubAdminAppHostname {
		switch (this.envName) {
		case 'local':
			return 'club.local.bestathletes.co:8080';
		case 'dev':
			return 'club.dev.bestathletes.co';
		case 'test':
		case 'testing':
					return 'club.testing.bestathletes.co';
		case 'stage':
		case 'staging':
			return 'club.stage.bestathletes.co';
		case 'production':
			return 'club.app.bestathletes.co';
		default:
			return 'club.local.bestathletes.co:8080';
		}
	}
	get PublicAppUrl(): PublicAppHostname {
		switch (this.envName) {
		case 'local':
			return 'app.local.bestathletes.co:8080/public';
		case 'dev':
			return 'dev.bestathletes.co/public';
		case 'test':
		case 'testing':
			return 'testing.bestathletes.co/public';
		case 'stage':
		case 'staging':
			return 'stage.bestathletes.co/public';
		case 'production':
			return 'app.bestathletes.co/public';
		default:
			return 'app.local.bestathletes.co:8080/public';
		}
	}

	get CDNBaseUrl(): CDN {
		switch (this.envName) {
		case 'local':
			return 'cdn-dev.bestathletes.co';
		case 'dev':
			return 'cdn-dev.bestathletes.co';
		case 'test':
		case 'testing':
			return 'cdn-testing.bestathletes.co';
		case 'stage':
		case 'staging':
			return 'cdn-stage.bestathletes.co';
		case 'production':
			return 'cdn.bestathletes.co';
		default:
			return 'cdn.bestathletes.co';
		}
	}

	gotoSettings() {
		if( this.AthleteAppActive ) {
			window.location.href = `https://${this.AthleteAppUrl}/settings`;
		} else {
			window.location.href = `https://${this.CoachAppUrl}/settings`;
		}
	}
	gotoDashboard() {
		console.log( `Current app: ${this.getAppType}`)
		if( this.AthleteAppActive ) {
			this.$router.push({ name: Routes.AthleteDashboard }).catch(e => {})
		} else if( this.ClubAdminAppActive ) {
			this.$router.push({ name: OrgAdminRoutes.OrgsAdminDashboard }).catch(e => {})
		} else if( this.AdminAppActive ) {
			this.$router.push({ name: AdminRoutes.AdminDashboard }).catch(e => {})
		} else if( this.CoachAppActive ) {
			this.$router.push({ name: Routes.CoachDashboard }).catch(e => {})
		} else {
			window.location.href = `https://${this.AthleteAppUrl}`;
		}
	}
	logoutAthleteApp(): void {
		window.location.href = `https://${this.AthleteAppUrl}/logout`;
	}
	openAdminApp(): void {
		if (this.AdminAppActive){
			this.$router.push('/').catch(() => {});
			return;
		}
		window.location.href = `https://${this.AdminAppUrl}`;
	}
	openAthleteApp(): void {
		window.location.href = this.AthleteLink;
	}
	openCoachApp(): void {
		window.location.href = this.CoachLink;
	}
	openClubAdminApp(): void {
		if (this.ClubAdminAppActive){
			this.$router.push('/').catch(() => {});
			return;
		}
		window.location.href = `https://${this.ClubAdminAppUrl}`;
	}
	openCoachLink(link: string = ''): void {
		window.location.href = `${this.CoachLink}/${link}`;
	}
	
	get AthleteLink(): string {
		return `https://${this.AthleteAppUrl}`;
	}
	gotoAthleteLink(link: string = '', dest: string = '_blank'): void {
		window.open(`${this.AthleteLink}/${link}`, dest);
	}
	get PublicLink(): string {
		return  'https://' + this.PublicAppUrl;
	}
	get PublicAthleteLink(): string {
		return `${this.AthleteLink}/public-a`
	}
	get CoachLink(): string {
		return `https://${this.CoachAppUrl}`;
	}
	gotoCoachLink(link: string = '', dest: string = '_blank'): void {
		window.open(`${this.CoachLink}/${link}`, dest);
	}
	get AdminLInk(): string {
		return `https://${this.AdminAppUrl}`;
	}

	gotoPublicLink(link: string = '', dest: string = '_blank'): void {
		window.open(`${this.PublicLink}${link}`, dest);
	}
	gotoAthletePublicLink(link: string = '', dest: string = '_blank'): void {
		window.open(`${this.PublicAthleteLink}/${link}`, dest);
	}
	gotoBestAthletesLink(link: string = '', dest: string = '_blank'): void {
		if( this.CoachAppActive ) this.gotoCoachLink(link, dest);
		if( this.AthleteAppActive) this.gotoAthleteLink(link, dest);
		this.gotoAthletePublicLink(link, dest);
	}

	selectProfile(profile: UserProfileModel, type?: 'athlete' | 'coach'): void{
		window.location.href = this.selectProfileUrl(profile, type);
	}

	selectProfileUrl(profile: UserProfileModel, type?: 'athlete' | 'coach'): string {
		let href = `${window.location.origin}${window.location.pathname}?u=${profile.id}`;
		if (type === 'athlete' && isCoachRoute(window.location.pathname)) {
			href = `${window.location.origin}/?u=${profile.id}`;
		} else if (type === 'coach' && !isCoachRoute(window.location.pathname)) {
			href = `${window.location.origin}/c?u=${profile.id}`;
		}
		return href;
	}
}
