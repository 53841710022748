
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, MindsetDisplayMixin, RyzerMixin, SportsSelectorMixin, StringsMixin, VuetifyMixin } from '@/mixins';
import { MindsetReportModel, AthleteProfileModel, CoachProfileModel } from '@/models'
import { athleteApi } from '@/api/AthleteApi';
import { mindsetReportApi } from '@/api/MindsetReportApi';
import { coachApi } from '@/api/CoachApi';

@Component({
    components: {}
})
export default class MindsetEditDialog extends Mixins(StringsMixin, VuetifyMixin, BAIconsMixin, SportsSelectorMixin, MindsetDisplayMixin, RyzerMixin) {
    @Prop({ required: true, default: undefined }) value: MindsetReportModel;
    @Prop({ type: Boolean }) small;

    async mounted() {
        await this.loadAthlete();
    }

    athlete: AthleteProfileModel;
    coach: CoachProfileModel;
    async loadAthlete(): Promise<AthleteProfileModel> {
        if( this.IsEmpty(this.value) ) return undefined;
        this.athlete = await athleteApi.findById(this.value.athleteId).catch(() => {return undefined});
        this.coach = await coachApi.findById(this.value.athleteId).catch(() => {return undefined});
        if( this.IsNotEmpty(this.value) && !this.value.HasDateOfBirth ) {
            if( this.IsNotEmpty(this.athlete) ) this.value.dateOfBirth = new Date(this.athlete.birthDate);
        }
        return this.athlete;
    }
    get UserName(): string {
        if( this.IsNotEmpty(this.athlete) ) return this.athlete.FullName;
        if( this.IsNotEmpty(this.coach) ) return this.coach.FullName;
        return this.FullName(this.value.firstName, this.value.lastName);
    }

	editingMindset: boolean = false;
	backupMindset: MindsetReportModel;
    get IsEditReady() {
        return this.editingMindset && (this.IsNotEmpty(this.athlete) || this.IsNotEmpty(this.coach));
    }
	onStartEditPlayer() {
        // make a copy of the mindset to edit
		this.backupMindset = new MindsetReportModel().load(this.value);
		this.editingMindset = true;
	}
	onCancelEditPlayer() {
        // player has not been changed, reset state
        this.value = new MindsetReportModel().load(this.backupMindset);
        this.backupMindset = undefined;
		this.editingMindset = false;
        this.$emit("cancel");
	}
	async onAcceptEditPlayer() {
        // save the updated mindset
        await mindsetReportApi.save(this.value);
        this.$emit("input", this.value);

        // reset state
		this.editingMindset = false;
        this.backupMindset = undefined;

        this.$emit("accept");
	}
}

