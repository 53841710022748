import { AthleteMetric } from '@/../types/enums';
export function getMetricColor(value: AthleteMetric): string {
	switch (value) {
	case AthleteMetric.Acceleration:
		return 'blue'
	case AthleteMetric.Speed:
		return 'purple';
	case AthleteMetric.Agility:
		return 'teal';
	case AthleteMetric.Power:
		return 'pink';
	case AthleteMetric.Recovery:
		return 'green';
	default:
		return 'yellow';
	}
}
