import { Component, Prop, Vue } from 'vue-property-decorator';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { orgAdminStore } from '@/store';
import { isEmpty } from '@/pipes';
import { TeamModel } from '@/models';

async function resolveOrganizationFromUrl(to, __, next): Promise <void> {
	const { organizationId } = to.params;
	if(organizationId) {
		orgAdminStore.loadOrganizationById({ organizationId });
	}
	next();
}

@Component({
	beforeRouteEnter: resolveOrganizationFromUrl,
	beforeRouteUpdate: resolveOrganizationFromUrl,
})
export class OrgResolverMixin extends Vue {
	@Prop({ required: true }) organizationId: string;

	async loadCurrentOrganization(): Promise<void> {
		if( isEmpty(this.organizationId) ) return;
		await orgAdminStore.loadOrganizationById({organizationId: this.organizationId});
		await this.organizationLoadComplete();
	}
	async organizationLoadComplete() {
	}
	async loadOrganizations() {
		await orgAdminStore.loadOrganizations();
	}
	async loadOrgTeams(organizationId: string) {
		if( isEmpty(organizationId) ) return;
		await orgAdminStore.loadOrganizationTeams({organizationId});
	}

	get IsOrgsLoaded(): boolean{
		return orgAdminStore.organizationsInitialized;
	}
	get IsOrganizationLoaded(): boolean {
		return orgAdminStore.organizationLoaded;
	}
	get IsOrgTeamsLoaded(): boolean {
		return orgAdminStore.loadOrganizationTeamsInitialized;
	}
	get CurrentOrganizationLoading(): boolean{
		return orgAdminStore.loadOrganizationByIdLoading;
	}

	get CurrentOrganization(): OrganizationModel {
		if( isEmpty(this.organizationId) ) return undefined;

		return orgAdminStore.organizations.find(o => o.id === this.organizationId);
	}
	get Organizations(): OrganizationModel[]{
		return orgAdminStore.organizations;
	}
	get OrgTeams(): Array<TeamModel> {
		return orgAdminStore.teamList;
	}
}
