// SportModel.ts 

import { FrontEndModel } from '../FrontEndModel';
import { SportName, BodyPart, BaseSoccerPositionValues } from "@/../types/enums";
import { AthleteReportCategoryHeading } from '@/models/athleteReport/AthleteReportModel';
import { isEmptyArray, isNotEmptyArray } from "@/pipes";
import { cloneDeep } from 'lodash';

const AthleteReportDefaultHeadings = [
	{
		name:'Technical', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Dribbling', rating: undefined, notes: undefined},
			{name:'Passing', rating: undefined, notes: undefined},
			{name:'Shooting', rating: undefined, notes: undefined},
			{name:'Control', rating: undefined, notes: undefined},
		]
	},
	{
		name:'Tactical', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Positioning', rating: undefined, notes: undefined},
			{name:'Movement', rating: undefined, notes: undefined},
			{name:'Awareness', rating: undefined, notes: undefined},
			{name:'Decisions', rating: undefined, notes: undefined},
		]
	},
	{
		name:'Physical', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Speed', rating: undefined, notes: undefined},
			{name:'Strength', rating: undefined, notes: undefined},
			{name:'Stamina', rating: undefined, notes: undefined},
			{name:'Agility', rating: undefined, notes: undefined}
		]
	},
	{
		name:'Mental', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Coachability', rating: undefined, notes: undefined},
			{name:'Confidence', rating: undefined, notes: undefined},
			{name:'Focus', rating: undefined, notes: undefined},
			{name:'Leadership', rating: undefined, notes: undefined},
		]
	},
	{
		name:'Social', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Teamwork', rating: undefined, notes: undefined},
			{name:'Respect', rating: undefined, notes: undefined},
			{name:'Sportsmanship', rating: undefined, notes: undefined},
			{name:'Adaptability', rating: undefined, notes: undefined},
		]
	},
];	

export class SportModel extends FrontEndModel {
    name!: SportName;
    icon: string = '';
    active: boolean = true;
    team: boolean = true;
    positions?: string[];
    bodyParts?: BodyPart[];
    physical: boolean = false;
    physicalLabels?: string[];
    
    athleteReports: boolean = true;
    athleteReportHeadings: Array<AthleteReportCategoryHeading> = [];
    scouting: boolean = false;
    scoutingLabels?: string[];
    scoutingMoments?: string[];
    scoutingEvaluations?: string[];

    proSearch: boolean = false;
    supportDepthCharts: boolean = false;

    constructor() {
        super();

        this.name = SportName.Soccer;
        this.icon = "mdi-soccer";
        this.active = true;
        this.team = true;
        this.positions = BaseSoccerPositionValues;
        this.bodyParts = [BodyPart.Foot];
        this.physical = true;
        this.physicalLabels = ['Acceleration','Speed','Agility','Power','Recovery'];
        this.athleteReports = true;
        this.athleteReportHeadings = [...AthleteReportDefaultHeadings];
        this.scouting = true;
        this.scoutingLabels = ['Technical','Tactical','Physical','Mental'];
        this.scoutingMoments = ['Attacking Organization','Attacking Transition','Attacking Set Plays','Defending Organization','Defending Transition','Defending Set Plays'];
        this.scoutingEvaluations = ['Competitive', 'Resilience', 'Intelligence', 'Speed', 'Presence'];
        this.proSearch = false;
        this.supportDepthCharts = false;
    }

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);

		if( isNotEmptyArray(obj['athleteReportHeadings']) ) {
			this.athleteReportHeadings = cloneDeep(obj['athleteReportHeadings']);
		} else {
			this.athleteReportHeadings = cloneDeep(AthleteReportDefaultHeadings);
		}

        return this;
    }

    get Positions(): string[] | null {
        if( !this.positions ) return null;
        if( this.positions.length == 0 ) return null;

        return this.positions;
    }
}