
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteRoutingMixin, VuetifyMixin, BAIconsMixin, UpgradeNowMixin } from '@/mixins';

@Component
export default class HolidaySale extends Mixins(VuetifyMixin, BAIconsMixin, AthleteRoutingMixin, UpgradeNowMixin) {
    @Prop({ type: Boolean }) wide;
    @Prop({ type: Boolean }) direct;
    @Prop({ type: Boolean }) showSale;

    get Class(): string {
        var result: string = this.wide? 'justify-center' : 'justify-end';
        if( this.direct ) result += " py-0";
        return result;
    }

    onClickSale() {
        if( this.direct ) this.gotoUpgrade();
        this.showSale = true;
    }
}
