
import { Component, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin, UpgradeNowMixin } from '@/mixins';

class Promotion {
    id: string = "";
    name: string = "Promotion";
    code: string = "BA5";
    discount: string = "5%";
    appliesTo: string = "All Subscriptions";
    hidden: boolean = false;
    current: boolean = true;
};

@Component
export default class PromotionsCard extends Mixins(VuetifyMixin, BAIconsMixin, UpgradeNowMixin) {
	baImg: string = 'https://cdn.bestathletes.co/static-assets/bestie-square-100x100.png';
	baImgSize: string = "100";

    showFoundPromos: boolean = false;
    mounted() {
        setTimeout(() => this.onFoundPromos(), 2500)
    }
    onFoundPromos() {
        this.showFoundPromos = true;
    }
    promotions: Array<Promotion> = [
        {
            id: "00000001",
            name: "Showcase 2024",
            code: "SHOWCASEATHLETE2024",
            discount: "10%",
            appliesTo: "Showcase Subscription",
            hidden: false,
            current: false,
        },
        {
            id: "00000002",
            name: "Elite",
            code: "ELITEATHLETE2024",
            discount: "20%",
            appliesTo: "Elite Subscription",
            hidden: false,
            current: false,
        },
        {
            id: "00000010",
            name: "Showcase Winter",
            code: "BASHOW20HOLIDAYS",
            discount: "25%",
            appliesTo: "Showcase Subscription",
            hidden: false,
            current: true,
        },
        {
            id: "00000020",
            name: "Elite Winter",
            code: "BAELITE50HOLIDAYS",
            discount: "50%",
            appliesTo: "Elite Subscription",
            hidden: false,
            current: true,
        },
    ];
}

