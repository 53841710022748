
import { MindsetReportModel } from '@/models';
import { QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { RyzerDataModel } from "@/../types/constants/ryzer"
import { CrudApi } from './CrudApi';

class MindsetReportApi extends CrudApi<MindsetReportModel>{
	constructor() {
		super('athleteMindsetReport', (obj) => new MindsetReportModel().load(obj));
	}

	async findAllByAthleteId(athleteId: string): Promise<MindsetReportModel[] | null> {
		const query: RepositoryQuery<MindsetReportModel> = { 
			search: athleteId,
			fields: ['athleteId']
		};
		const options: QueryOptions = {};
		const reportsFound = await this.queryAll(query, options);
		
		if (reportsFound.count === 0) return null;

		return reportsFound.docs;
	}

	async createMindsetUser(userId: string): Promise<RyzerDataModel> {
		try{
			const response: RyzerDataModel = await CrudApi.Api((c) => c.post(`/${this.resource}/${userId}`));
			return response;
		}catch(e){
			var errorResponse: RyzerDataModel;
			errorResponse.Error = e.message;
			return errorResponse;
		}
	}

	async prepareMindsetTest(userId: string): Promise<RyzerDataModel> {
		try{
			const response: RyzerDataModel = await CrudApi.Api((c) => c.post(`/${this.resource}/${userId}/test`));
			return response;
		}catch(e){
			var errorResponse: RyzerDataModel;
			errorResponse.Error = e.message;
			return errorResponse;
		}
	}

	async startMindsetAssessment(userId: string) {
		try {
			await CrudApi.Api((c) => c.post(`/${this.resource}/${userId}/start`));
		} catch(e) {
			console.log(`Error Starting Mindset Assessment`);
		}
	}

	async sendMindsetInvitation(athleteId: string, coachId: string): Promise<RyzerDataModel> {
		try{
			const response: RyzerDataModel = await CrudApi.Api((c) => c.post(`/${this.resource}/${athleteId}/invite`, {coachId}));
			return response;
		}catch(e){
			var errorResponse: RyzerDataModel;
			errorResponse.Error = e.message;
			return errorResponse;
		}
	}
}
export const mindsetReportApi = new MindsetReportApi();
