
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, StringsMixin, FormRulesMixin, VuetifyMixin, StatusMixin, AuthMixin, SportsSelectorMixin, AthleteRoutingMixin } from '@/mixins';
import { AthleteProfileModel, UserModel } from '@/models';
import { SportModel } from "@/models/sport/SportModel";
import { UserAccountModel } from '@/models/user/UserAccountModel';
import { Gender } from '@best-athletes/ba-types';
import TOSCheckbox from '@/components/forms/TOSCheckbox.vue';
import ProfilePictureUpload from '@/components/forms/ProfilePictureUpload.vue';
import { userApi } from '@/api/UserApi';
import { RoleName } from '@/../types';
import { setGAUserProperties } from '@/plugins/firebase';
import { User } from '@auth0/auth0-spa-js';
import { sportStore, userStore } from '@/store';
import { SportName } from '@/../types/enums';

@Component({
    components: {
        TOSCheckbox,
        ProfilePictureUpload
    }
})
export default class OnboardingDialog extends Mixins(BAIconsMixin, StringsMixin, FormRulesMixin, VuetifyMixin, StatusMixin, AuthMixin, SportsSelectorMixin, AthleteRoutingMixin) {
    @Prop({type: Boolean}) icon;
    @Prop() sportName: SportName;
    Gender = Gender;

    newAthlete: AthleteProfileModel = new AthleteProfileModel();
    password: string = '';
    passwordConfirm: string = '';

    showOnboardingDialog: boolean = false;
	$refs:{ form: HTMLFormElement }

    async onRegisterStart() {
        this.showOnboardingDialog = true;
    }
    onCancel() {
        this.showOnboardingDialog = false;
    }
    onError() {
        this.processing = false;
    }

    async createUser(): Promise<UserAccountModel> {
        this.setInfo( `Creating account for ${this.newAthlete.FullName}` );

        try{
            const user: UserModel = new UserModel().load({
                roles: [RoleName.Athlete, RoleName.FreeUser],
                email: this.newAthlete.email,
                email_verified: false,
                user_metadata: {
                    phone: this.newAthlete.PhoneNumber,
                    gender: this.newAthlete.gender,
                    tosAccepted: this.newAthlete.tosAgreed,                
                },
                pictureUrl: this.newAthlete.pictureUrl,
                firstName: this.newAthlete.firstName,
                lastName: this.newAthlete.lastName,
                password: this.password,
            })

            const newUser: UserAccountModel = await userApi.createUser(user);
            if( this.IsEmpty(newUser) ) {
                this.setError(`Error creating user ${user.email}`);
                throw( Error(`Unable to create New User: ${user.email}`));
            }
            return newUser;
        } catch(e) {
            throw(e);
        }
    }
    async createAthlete(): Promise<AthleteProfileModel> {
        this.setInfo( `Creating Athlete Profile for ${this.newAthlete.FullName}` );

        if( this.IsEmpty(this.newAthlete.athleteSports) ) {
            await sportStore.loadSports();
            
            var athleteSport: SportModel = sportStore.sports.find(sport => sport.name.includes(this.sportName));
            if( this.IsEmpty(athleteSport) ) {
                // default to soccer
                athleteSport = sportStore.sports.find(sport => sport.name === SportName.Soccer);
            }
            // add selected sport to the athlete sports
            if( this.IsNotEmpty(athleteSport?.id) ) {
                this.newAthlete.athleteSports = [athleteSport.id];
            }
        }
        this.newAthlete.autocompleteDiscoverable = true;
        this.newAthlete.recruitingProfileComplete = true;

        try {
            const athlete: AthleteProfileModel = await userStore.createAthleteProfile({athlete: this.newAthlete});

            if( this.IsEmpty(athlete) ) {
                this.setError( `Error creating athlete ${this.newAthlete.Email}` );
                throw( Error(`Unable to create New Athlete: ${this.newAthlete.Email}`));
            }

            setGAUserProperties( {is_athlete: true} );

            this.setInfo( `Athlete Profile created for ${athlete.FullName}` );
            return athlete;
        } catch(e) {
            this.setError(`Error creating athlete: ${e}`);
            return undefined;
        }
    }

    processing: boolean = false;
    loginRequest: boolean = false;
    async submitForm() {      
        if( !this.$refs.form.validate() ) {
            this.setError( "Registration incomplete. Please complete all of the required fields" );
            return;
        }

        try {
            this.processing = true;

            this.setInfo( `Creating user account` );
            const newUser: UserAccountModel = await this.createUser();
            if( this.IsEmpty(newUser) ) {
                this.processing = false;
                return;
            }

            this.setInfo( `Account created. You will now be asked to log in.` );
            const success = await this.loginUsingPopup(newUser.email);
            if( !success ) return;

            this.setInfo( `Retrieving account information...` );
            const sportName = this.sportName;
            const user: User = await this.updateAfterLogin();
            this.setInfo( `Initializing Athlete Profile for ${user.given_name}` );
            this.sportName = sportName;
            this.setInfo( `Creating Athlete Profile for ${user.given_name}` );
            const newAthlete: AthleteProfileModel = await this.createAthlete();
			await userStore.acceptTermsOfService( newAthlete.tosAgreed );
            this.setInfo( `Linking Athlete Profile for ${newAthlete.FullName}` );
			await userStore.useAthleteProfile( { profileId: newAthlete.id } );
            this.setInfo( `Welcome to Best Athletes, ${newAthlete.FullName}` );
            await this.$emit('complete');
            window.location.reload();
        } catch(e) {
            const err: Error = e;
            if( err.message.includes('status code 500') ) {
                this.setError( `Unable to create user. User already exists.` );
                this.loginRequest = true;
            } else {
                this.setError( `Error creating user: ${e}` );
                this.gotoLogout();
            }
        }

        // close dialog
        this.processing = false;
    }

	async onLoginRequest() {
        try{
            this.loginRequest = true;
            this.setStatus(`Log in using your Best Athletes account`);
            const success = await this.loginUsingPopup();

            if( success ) {
                this.setStatus(`User logged in. Retrieving user information...`);
                const user: User = await this.updateAfterLogin();
                if( this.IsNotEmpty(user) ) {
                    this.setStatus(`User Logged in successfully. Refreshing page...`);
                    this.reloadWindow();
                }
            } else {
                this.setError("User login cancelled");
                this.loginRequest = false;
            }
        } catch(e) {
            this.loginRequest = false;
            this.setError(e);
            this.gotoLogout();
        }
	}
}

