/* RoutingMixin.ts */

import { Component, Mixins } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component
export class RoutingMixin extends Mixins() {
    
    gotoUrl(url: string, dest: string = "_blank") {
		if( !url ) return;
		if( !url.includes('http') ) {
			url = `http://${url}`;
		}
        window.open(url, dest);
    }

	getRoute(name: string, params = { ...this.$route.params } ): Partial<Route> {
		return { name, params }
	}

	async gotoRoute(route: Partial<Route>) {
		this.$router.push(route);
		this.$vuetify.goTo(0);
	}
}
