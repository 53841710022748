import { Component, Mixins, Vue } from 'vue-property-decorator';
import UpgradeNowButton from '@/components/forms/UpgradeNowButton.vue';
import { UserSubscriptions } from '@/../types/interfaces';
import { SubscriptionModel } from '@/models/stripe/SubscriptionModel';
import { userApi } from '@/api/UserApi';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { AppHostnameMixin } from '.';

@Component({
	components:{
		UpgradeNowButton
	}
})
export class UpgradeNowMixin extends Mixins(AppHostnameMixin) {
	mounted() {
		this.loadUserSubscriptions();
	}

	get IsSubscriptionAvailable(): boolean {
		if( !this.IsSubscriptionsLoaded ) return false;
		if( this.$route.name === Routes.UpgradeSubscriptionPage ) return false;
		if( this.$route.name === Routes.AthleteCheckoutPage ) return false;
		if( !this.AthleteAppActive ) return false;
		return( !this.HasUserPaidSubscriptions );
	}
	get HasUserPaidSubscriptions(): boolean {
		return this.UserPaidSubscriptions.length > 0;
	}
	get UserPaidSubscriptions(): SubscriptionModel[] | null {
		if (this.userSubscriptions === null) return null;
		return this.userSubscriptions.athleteProfiles.subscriptions.filter(sub => !sub.plan.free);
	}
	get baSupportEmail(): string {
		return 'support@bestathletes.co';
	}
	get baSupportPhone(): string {
		return 'contact@bestathletes.co'
	}

	get Month() {
		const now = new Date();
		return now.getMonth();
	}
    get IsDecember(): boolean {
        return this.Month === 11; // 11 corresponds to December
    }
    get IsSpring(): boolean {
        return this.Month >= 3 && this.Month < 6;
    }
    get IsSummer(): boolean {
        return this.Month >= 6 && this.Month < 9;
    }
    get IsAutumn(): boolean {
        return this.Month >= 9 && this.Month < 11;
    }
    get IsWinter(): boolean {
        return this.Month === 11 || this.Month < 3;
    }

	subscriptionsReady = false;
	get IsSubscriptionsLoaded(): boolean {
		return this.subscriptionsReady;
	}

	userSubscriptions: UserSubscriptions<SubscriptionModel> | null = null;
	async loadUserSubscriptions(): Promise<void> {
		this.subscriptionsReady = false;
		this.userSubscriptions = await userApi.getUserSubscriptions();
		this.subscriptionsReady = true;
	}
}
