
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, StringsMixin, VuetifyMixin } from '@/mixins';

@Component
export default class SideNavSectionTitle extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin) {
	@Prop({type: Boolean, default: false}) mini: boolean;
	@Prop({type: String}) icon;
	@Prop({type: String}) title;
	@Prop({type: String}) textColor;
	@Prop({type: Boolean}) group;
	@Prop({type: Boolean}) expand;
	@Prop({type: Boolean}) expanded;

	defaultTextColor: string = 'baColorGray8';
	get HasIcon(): boolean {
		return this.IsNotEmpty(this.icon);
	}
	get HasTitle(): boolean {
		return this.IsNotEmpty(this.title);
	}
	get IconColor(): string {
		if( this.IsEmpty(this.textColor) ) return this.defaultTextColor;
		return this.textColor;
	}
	get TextColor(): string {
		if( this.IsEmpty(this.textColor) ) return this.defaultTextColor;
		return this.textColor;
	}

	onClickExpand() {
		this.expanded = !this.expanded;
		if( this.expanded ) this.$emit('expand', this.expanded);
		else this.$emit('collapse', this.expanded);
	}
}
