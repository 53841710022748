
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, SportsSelectorMixin, StringsMixin, VuetifyMixin } from '@/mixins';
import { AthleteAssessmentDataModel, AthleteProfileModel } from '@/models'
import { athleteApi } from '@/api/AthleteApi';
import { athleteAssessmentDataApi } from '@/api/AthleteAssessmentDataApi';

@Component({
    components: {}
})
export default class AssessmentEditDialog extends Mixins(StringsMixin, VuetifyMixin, BAIconsMixin, SportsSelectorMixin) {
    @Prop({ required: true, default: undefined }) value: AthleteAssessmentDataModel;
    @Prop({ type: Boolean }) small;

    async mounted() {
        await this.loadAthlete();
    }

    athlete: AthleteProfileModel;
    async loadAthlete(): Promise<AthleteProfileModel> {
        if( this.IsEmpty(this.value) ) return undefined;
        this.athlete = await athleteApi.findById(this.value.athleteId);
        return this.athlete;
    }

	editingAssessment: boolean = false;
	backupAssessment: AthleteAssessmentDataModel;
    get IsEditReady() {
        return this.editingAssessment && this.IsNotEmpty(this.athlete);
    }
	onStartEditPlayer() {
        // make a copy of the assessment to edit
		this.backupAssessment = new AthleteAssessmentDataModel().load(this.value);
        this.sprintUpdated = true;
        this.agilityUpdated = true;
        this.powerUpdated = true;
        this.recoveryUpdated = true;
		this.editingAssessment = true;
	}
	onCancelEditPlayer() {
        // player has not been changed, reset state
        this.value = new AthleteAssessmentDataModel().load(this.backupAssessment);
        this.backupAssessment = undefined;
		this.editingAssessment = false;
        this.$emit("cancel");
	}
	async onAcceptEditPlayer() {
        // save the updated assessment
        await athleteAssessmentDataApi.save(this.value);
        this.$emit("input", this.value);

        // reset state
		this.editingAssessment = false;
        this.backupAssessment = undefined;

        this.$emit("accept");
	}


    // edit dialog
    sprintUpdated: boolean = false;
    agilityUpdated: boolean = false;
    powerUpdated: boolean = false;
    recoveryUpdated: boolean = false;
    onAgilityUpdated() {
        this.agilityUpdated = false;
        this.value.computeAgility();
        this.agilityUpdated = true;
    }
    onPowerUpdated() {
        this.powerUpdated = false;
        this.value.computePower();
        this.powerUpdated = true;
    }
    onRecoveryUpdated() {
        this.recoveryUpdated = false;
        this.value.computeRecovery();
        this.recoveryUpdated = true;
    }
    onSprintUpdated() {
        this.sprintUpdated = false;
        this.value.computeAcceleration();
        this.value.computeSpeed();
        this.sprintUpdated = true;
    }
}

