import { AthleteMetric } from '@/../types/enums';
export function getMetricIcon(value: AthleteMetric): string {
	switch (value) {
	case AthleteMetric.Acceleration:
		return 'SpeedometerSvg'
	case AthleteMetric.Speed:
		return 'StopwatchSvg';
	case AthleteMetric.Agility:
		return 'AgilitySvg';
	case AthleteMetric.Power:
		return 'LightningSvg';
	case AthleteMetric.Recovery:
		return 'HeartVitalSvg';
	default:
		return '';
	}
}
