// AthleteReportsMixin.ts

import { Component, Mixins } from 'vue-property-decorator';
import { StringsMixin } from './StringsMixin';
import { AthleteReportCategoryHeading, AthleteReportCategory } from '@/models';
import { DataTableHeader } from 'vuetify';

@Component
export class AthleteReportsMixin extends Mixins(StringsMixin) {
    get AthleteReportTableHeaders(): DataTableHeader<any>[] {
        return [
            {text: 'Heading', value:'name', sortable: false, align: 'start', width:'100' },
            {text: 'Categories', value:'categories', align: 'start', sortable: false },
            {text: '', value:'actions', sortable: false, align: 'end', width:'25' },
        ]
    }
    newAthleteReportHeading: string = '';
    onAddAthleteReportHeading() {
        const heading: AthleteReportCategoryHeading = new AthleteReportCategoryHeading;
        heading.name = this.newAthleteReportHeading;
        this.newAthleteReportHeading = '';
        this.onAddAthleteReportHeadingComplete(heading);
    }
    onAddAthleteReportHeadingComplete(heading: AthleteReportCategoryHeading){}      // this function must be overriden
}