/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Route, RouteConfig } from 'vue-router';
import { requireRolesGuard } from '@/router/routeGuards/requireRolesGuard';
import OrgAdminSideNavLayout from '@/layouts/OrgAdminSideNavLayout.vue';
import * as OrgAdminRoutes from '@/../types/constants/org-admin.routes';
import { ClubAdminAppRoles } from '@/../types/constants/role-lists';
import { HinderRouteMetadata } from './router';
import TeamCreate from '@/views/TeamCreate.vue';
import TeamShare from '@/views/TeamShare.vue';
import TeamEventCreate from '@/views/TeamEventCreate.vue';
import TeamDashboard from '@/views/TeamDashboard.vue';
import { getModule } from 'vuex-module-decorators';
import OrgAdminModule from '@/store/orgAdmin/OrgAdmin.store';
import * as Routes from '@/../types/constants/web_client_user.routes';
getModule(OrgAdminModule); // Ensure the OrgAdminStore is loaded for this module

const OrgsAdminDashboard = () => import(/* webpackChunkName: "OrgAdminModule" */ '@/views/orgAdmin/OrgsAdminDashboard.vue');
const OrgAdminSettings = () => import(/* webpackChunkName: "OrgAdminModule" */ '@/views/orgAdmin/OrgAdminSettings.vue');
const OrgAdminManageTeams = () => import(/* webpackChunkName: "OrgAdminModule" */ '@/views/orgAdmin/OrgAdminManageTeams.vue');
const OrgAdminManagePlayers = () => import(/* webpackChunkName: "OrgAdminModule" */ '@/views/orgAdmin/OrgAdminManagePlayers.vue');
const OrgAdminManageStaff = () => import(/* webpackChunkName: "OrgAdminModule" */ '@/views/orgAdmin/OrgAdminManageStaff.vue');
const AthleteRecruitingProfileView = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteRecruitingProfileView.vue')
const AthleteRecruitingProfileVideosView = () => import(/* webpackChunkName: "AthleteModule" */ '@/views/AthleteRecruitingProfileVideosView.vue')
const CoachTeamProgressDashboard = () => import(/* webpackChunkName: "CoachModule" */ '@/views/CoachTeamProgressDashboard.vue');

export const OrgAdminRouteConfigs: RouteConfig[] = [
	{
		path: '/',
		component: OrgAdminSideNavLayout,
		beforeEnter: requireRolesGuard(ClubAdminAppRoles, 'any'),
		props: true,
		children: [
			{
				path: '',
				name: OrgAdminRoutes.OrgsAdminDashboard,
				component: OrgsAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Clubs Administration"
				},
			},
			{
				path: ':organizationId/settings',
				name: OrgAdminRoutes.OrgAdminSettings,
				component: OrgAdminSettings,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Club Settings"
				},
			},
			{
				path: ':organizationId/teams',
				name: OrgAdminRoutes.OrgAdminManageTeams,
				component: OrgAdminManageTeams,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Club Dashboard"
				},
			},
			{
				path: ':organizationId/team/dashboard/:teamId',
				name: OrgAdminRoutes.TeamDashboard,
				component: TeamDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Team Dashboard',
				}
			},
			{
				path: ':organizationId/team/create/:currentStep?',
				name: OrgAdminRoutes.TeamCreate,
				component: TeamCreate,
				props: (to: Route) => {
					return {
						currentStep: to.params['currentStep'],
						organizationId: to.params['organizationId'],
						teamDashboardRoute: OrgAdminRoutes.TeamDashboard,
						dashboardRoute: OrgAdminRoutes.OrgAdminManageTeams,
						selfRoleOptional: true,
					}
				},
				meta: <HinderRouteMetadata>{
					pageTitle: 'Create Team',
				}
			},
			{
				path: ':organizationId/team/:teamId/edit/:currentStep?',
				name: OrgAdminRoutes.EditTeam,
				component: TeamCreate,
				meta: {
					pageTitle: 'Edit Team',
				},
				props: (to: Route) => {
					return {
						editTeamId: to.params['teamId'],
						editAll: to.params['editAll'],
						teamDashboardRoute: OrgAdminRoutes.TeamDashboard,
						dashboardRoute: OrgAdminRoutes.OrgAdminManageTeams,
						selfRoleOptional: true,
					}
				},
			},
			{
				path: ':organizationId/team/:teamId/share/:sharingUrlId?',
				name: OrgAdminRoutes.ShareTeam,
				component: TeamShare,
				props: true,
				meta: {
					pageTitle: 'Share Team Roster',
				},
			},
			{
				path: ':organizationId/team/dashboard/:teamId/event/create',
				name: OrgAdminRoutes.TeamEventCreate,
				component: TeamEventCreate,
				props: true,
				meta: {
					hideAppBar: true,
				}
			},
			{
				path: ':organizationId/team/dashboard/:teamId/event/:teamEventId/edit',
				name: OrgAdminRoutes.TeamEventEdit,
				component: TeamEventCreate,
				props: true,
				meta: {
					hideAppBar: true,
				}
			},
			{
				path: ':organizationId/players',
				name: OrgAdminRoutes.OrgAdminManagePlayers,
				component: OrgAdminManagePlayers,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Manage Players"
				},
			},
			{
				path: ':organizationId/staff',
				name: OrgAdminRoutes.OrgAdminManageStaff,
				component: OrgAdminManageStaff,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: "Manage Staff"
				},
			},
			{
				path: ':organizationId/team/:teamId/athlete-progress',
				name: Routes.CoachTeamProgressDashboard,
				component: CoachTeamProgressDashboard,
				props: true,
				meta: <HinderRouteMetadata> {
					pageTitle: "Athlete Progress"
				}
			},
			{
				path: 'athlete/:athleteId',
				name: Routes.CoachViewAthlete,
				component: AthleteRecruitingProfileView,
				props: true,
				meta: {
					pageTitle: "Recruiting Profile"
				}
			},
			{
				path: 'athlete/:athleteId/videos',
				name: Routes.CoachViewAthleteVideos,
				component: AthleteRecruitingProfileVideosView,
				props: true,
				meta: {
					pageTitle: "All Videos"
				}
			},
		]
	},
];